import MDButton from 'components/MDButton'
import React, { useState } from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import PropTypes from 'prop-types'
import MDTypography from 'components/MDTypography'
import { useAppServices, useUploadImage } from 'hook/services'
import { useAgencyInfo } from 'context/agency'
import { Edit, EventNote, Note } from '@mui/icons-material'
import Editor from '../editor'
import { useLocation, useParams } from 'react-router-dom'
import MDInput from 'components/MDInput'

const TypeEditModal = ({ handleRefresh, data }) => {
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const params = useParams()
  const handlemodal = (event) => {
    event.stopPropagation()
    setOpenAddProduct(true)
  }
  const style = {
    width: 650,
  }
  function AddProduct({ open, onClose }) {
    const [agency] = useAgencyInfo()
    const { state } = useLocation()
    const [processing, setProcessing] = useState(false)
    const [message, setmessage] = useState(data?.description || '')
    const AppService = useAppServices()
    const handleSubmit = async (e) => {
      setProcessing(true)
      e.preventDefault()
      const payload = {
        description: message,
        title: e.target.title.value,
        agency_id: agency._id,
        type: 'super_admin',
        _id: data._id,
      }
      const { response } = await AppService.tickets_type.update({
        payload,
        toaster: true,
      })
      setProcessing(false)
      if (response) {
        onClose()
        handleRefresh()
      }
    }

    return (
      <MDBox>
        <MDModal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDTypography variant="h6">Edit Type</MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography
                variant="button"
                sx={{ fontSize: '14px', fontWeight: '500', marginBottom: '10px' }}
              >
                Title
              </MDTypography>
              <MDInput
                defaultValue={data?.title}
                label="Title"
                placeholder="Title"
                name="title"
                fullWidth
              />
            </MDBox>
            <MDBox>
              <MDTypography
                variant="button"
                sx={{ fontSize: '14px', fontWeight: '500', marginBottom: '10px' }}
              >
                Description
              </MDTypography>
              <Editor
                handleChange={(data) => {
                  console.log('html', data)
                  setmessage(data)
                }}
                data={message}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mt: 4, mb: 1 }}
            >
              <MDBox>
                <MDButton
                  variant="gradient"
                  color="white"
                  sx={{
                    mr: 2,
                    '&:hover': {
                      background: 'white',
                    },
                  }}
                  size="small"
                  onClick={onClose}
                >
                  Cancel
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="dark"
                  type="submit"
                  loading={processing}
                  disabled={processing}
                  size="small"
                >
                  Edit
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDModal>
      </MDBox>
    )
  }
  const closeAddProduct = () => {
    // if (subscription?._id)
    setOpenAddProduct(false)
  }
  return (
    <>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
      <Edit sx={{ cursor: 'pointer' }} onClick={handlemodal} />
    </>
  )
}

export default TypeEditModal
TypeEditModal.propTypes = {
  open: PropTypes.bool,
  handleRefresh: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}
