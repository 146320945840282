import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import React from "react";
import GroupModal from "../Modals/GroupModal"
import DataTable from "../DataTable";
import dataTableData from "../data/group"
function Group() {
  const { data, processing,handleRefresh } = dataTableData();

  return (
    <MDBox>
      <Grid container rowSpacing={3}>
        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
          <GroupModal handleRefresh={handleRefresh}/>
        </Grid>
        <Grid item xs={12}>
        <DataTable table={data} canSearch />
        </Grid>

      </Grid>
    </MDBox>
  );
}

export default Group;
