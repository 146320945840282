/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import env from "config";
// import { useAppServices } from "hook/services";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAgencyInfo } from "context/agency";
import { Button } from "@mui/material";
import { useAppServices } from "hook/services";
import { Delete } from "@mui/icons-material";
import CategoryEditModal from "../Modals/categoryEditModal";

export default function Default() {
  const AppService = useAppServices();
  const [agency] = useAgencyInfo();
  const [processing, setProcessing] = useState(true);
  const [data, setdata] = useState({
    columns: [
      { Header: "Title", accessor: "title" },
      { Header: "description", accessor: "description" },
      { Header: "Actions", accessor: "actions" },
    ],

    rows: [],
  });
  const handleDelete=async(id)=>{
    const { response } = await AppService.tickets_group.delete({
      query: `_id=${id}`,
    });
    if(response){
      onLoad()
    }
  }
  const extractFirst4Words = (htmlString) => {
    // Creating a temporary div to parse the HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
  
    // Extracting the text content from the temporary div
    const textContent = tempDiv.textContent || tempDiv.innerText;
  
    // Extracting the first 4 words
    const words = textContent.split(' ');
    const first4Words = words.slice(0, 4).join(' ');
  
    // Adding ellipsis if there are more than 4 words
    const displayText = words.length > 4 ? `${first4Words}...` : first4Words;
  
    return displayText;
  };
  const GetTicketsCategory = async (e) => {
    const { response } = await AppService.tickets_group.get({
      query: `agency_id=${agency._id}`,
    });
    console.log(response.data, "GetTicketsCategory");
    if (response) {
      var temp_data = [];
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        var temp = {
          title: element.title,
          description: extractFirst4Words(element.description),
          actions: (
            <>
            <CategoryEditModal data={element} handleRefresh={onLoad}/>
            <Delete sx={{cursor:"pointer",marginLeft:"10px"}} onClick={()=>handleDelete(element._id)} />
            </>
          ),
        };
        temp_data.push(temp);
      }
      setdata({
        columns: [
          { Header: "Title", accessor: "title" },
          { Header: "description", accessor: "description" },
          { Header: "Actions", accessor: "actions" },
        ],

        rows: temp_data,
      });
    }

    setProcessing(false);
  };
  const onLoad = () => {
    GetTicketsCategory();
  };
  useEffect(() => {
    onLoad();
  }, []);
  return {
    data: data,
    processing: processing,
    handleRefresh: GetTicketsCategory,
  };
}
