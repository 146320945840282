import { Grid } from '@mui/material'
import React from 'react'
import PlanModal from '../Modals/PlanModal'
import dataTableData from '../data/plan'
import DataTable from '../DataTable'
import MDBox from 'components/MDBox'

function Plan() {
  const { data, processing, handleRefresh } = dataTableData()
  return (
    <MDBox>
      <Grid container rowSpacing={3}>
        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
          <PlanModal handleRefresh={handleRefresh} />
        </Grid>
        <Grid item xs={12}>
          <DataTable table={data} canSearch />
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default Plan
