import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import React from "react";
import TypeModal from "../Modals/TypeModal";
import dataTableData from "../data/type";
import DataTable from "../DataTable";

function Type() {
  const { data, processing,handleRefresh } = dataTableData();
  return (
   <Card>
     <MDBox>
      <Grid container rowSpacing={3}>
        <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
          <TypeModal handleRefresh={handleRefresh}/>
        </Grid>
        <Grid item xs={12}>
          <DataTable table={data} canSearch />
        </Grid>
      </Grid>
    </MDBox>
   </Card>
  );
}

export default Type;
