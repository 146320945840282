/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import env from "config";
// import { useAppServices } from "hook/services";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAgencyInfo } from "context/agency";
import { Button } from "@mui/material";
import { useAppServices } from "hook/services";
import { Delete } from "@mui/icons-material";
import TypeEditModal from "../Modals/TypeEditModal";

export default function SnapshotData() {
  const AppService = useAppServices();
  const [agency] = useAgencyInfo();
  const [processing, setProcessing] = useState(true);
  const [data, setdata] = useState({
    columns: [
      { Header: "Title", accessor: "title" },
      { Header: "Actions", accessor: "actions" },
    ],

    rows: [],
  });
  const handleDelete = async (id) => {
    const { response } = await AppService.notes_type.delete({
      query: `_id=${id}`,
    });
    if (response) {
      onLoad();
    }
  };
  const GetTicketsType = async (e) => {
    const { response } = await AppService.notes_type.get({
      query: `type=super_admin`,
    });
    console.log(response.data, "GetTicketsType");
    if (response) {
      var temp_data = [];
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        var temp = {
          title: element.title,
          actions: (
            <>
            <TypeEditModal data={element} handleRefresh={onLoad}/>
              <Delete
                sx={{ cursor: "pointer", marginLeft: "10px" }}
                onClick={() => handleDelete(element._id)}
              />
            </>
          ),
        };
        temp_data.push(temp);
      }
      setdata({
        columns: [
          { Header: "Title", accessor: "title" },
          { Header: "Actions", accessor: "actions" },
        ],

        rows: temp_data,
      });
    }

    setProcessing(false);
  };
  const onLoad = () => {
    GetTicketsType();
  };
  useEffect(() => {
    onLoad();
  }, []);
  return {
    data: data,
    processing: processing,
    handleRefresh: GetTicketsType,
  };
}
