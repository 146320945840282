import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import localforage from 'localforage'
import Card from '@mui/material/Card'
import MDButton from 'components/MDButton'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import Alert from '@mui/material/Alert'
import env from 'config'
import { useBrandInfo } from 'context/brand'
import Stack from '@mui/material/Stack'
import ghlLogo from '../../../../assets/images/ghl-logo.avif'
function Integration() {
  const [brand] = useBrandInfo()
  const [saved_data, setsaved_data] = React.useState('')
  const [user_id, setuser_id] = React.useState('')
  const [processing, setProcessing] = React.useState(false)
  const [errormsg, seterrormsg] = React.useState('')
  const [response, setresponse] = React.useState('')
  const [strpe_api_key, setstrpe_api_key] = React.useState('')
  const [strpe_public_key, setstrpe_public_key] = React.useState('')
  const BASE_URL = `${env.API_URL}/v1`

  var axios = require('axios')
  const color = 'info'
  React.useEffect(async () => {
    const localBrand = await localforage.getItem('user')
    setuser_id(localBrand._id)
    // alert(localBrand.stripe.customer_id)
    axios
      .get(BASE_URL + '/superadmin/settings/filter')
      .then((response) => {
        setsaved_data(response.data.data)
        localforage.setItem('settings', response.data.data)
        setstrpe_api_key(response.data.data.stripe_api_key)
        setstrpe_public_key(response.data.data.strpe_public_key)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  var data = ''
  const handleapi = (e) => {
    e.preventDefault()
    setProcessing(true)

    data = {
      stripe_api_key: e.target.strpe_api_key.value,
      strpe_public_key: e.target.strpe_public_key.value,
    }
    console.log(data)

    // 4848 7150 5203 7927
    if (saved_data === '') {
      axios
        .post(BASE_URL + '/superadmin/settings/submit', { data })
        .then(async function (response) {
          console.log(response.data)
          setProcessing(false)
          setresponse(response.data.message)
          setTimeout(() => {
            setresponse('')
          }, 2000)
          setsaved_data(response.data)
        })
        .catch(function (error) {
          setProcessing(false)
          seterrormsg(error.response.data.message)
          setTimeout(() => {
            seterrormsg('')
          }, 2000)
          console.log(error.response.data)
        })
    } else {
      axios
        .post(BASE_URL + '/superadmin/settings/update/' + saved_data.data._id, { data })
        .then(async function (response) {
          console.log(response.data)
          setProcessing(false)
          setresponse(response.data.message)
          setTimeout(() => {
            setresponse('')
          }, 2000)
          setsaved_data(response.data)
        })
        .catch(function (error) {
          setProcessing(false)
          console.log(error.response.data)
          seterrormsg(error.response.data.message)
          setTimeout(() => {
            seterrormsg('')
          }, 2000)
        })
    }
  }
  return (
    <MDBox pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid container mt={5} mb={2} justifyContent="center">
            {errormsg != '' ? (
              <Stack sx={{ width: '90%' }} spacing={2}>
                <Alert severity="error">{errormsg}</Alert>
              </Stack>
            ) : (
              <></>
            )}
            {response != '' ? (
              <Stack sx={{ width: '90%' }} spacing={2}>
                <Alert severity="success">{response}</Alert>
              </Stack>
            ) : (
              <></>
            )}

            <Grid item xs={11}>
              <MDBox pt={4} px={3}>
                <form onSubmit={handleapi}>
                  <MDBox display="flex">
                    <MDBox sx={{ display: 'flex', flexDirection: 'column' }}>
                      <img src={ghlLogo} alt="logo" width={100} />

                      {saved_data?.agency_ghl != undefined ? (
                        <MDButton
                          variant="contained"
                          color="success"
                          size="small"
                          sx={{ width: '100px' }}
                        >
                          <a
                            href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.AGENCY}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.SCOPE}`}
                            style={{ color: 'white' }}
                          >
                            Agency Refresh
                          </a>
                        </MDButton>
                      ) : (
                        <MDButton
                          variant="contained"
                          color="info"
                          size="small"
                          sx={{ width: '100px' }}
                        >
                          <a
                            href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.AGENCY}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.SCOPE}`}
                            style={{ color: 'white' }}
                          >
                            Agency connect
                          </a>
                        </MDButton>
                      )}
                    </MDBox>
                    <MDBox sx={{ display: 'flex', flexDirection: 'column',marginLeft:'3rem' }}>
                      <img src={ghlLogo} alt="logo" width={100} />

                      {saved_data?.ghl ? (
                        <MDButton
                          variant="contained"
                          color="success"
                          size="small"
                          sx={{ width: '100px' }}
                        >
                          <a
                            href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.ASSOCIATE}&client_id=${env.GHL.Location_CLIENT_ID}&scope=${env.GHL.Location_SCOPE}&state=${brand.ghl?.location_id}`}
                            style={{ color: 'white' }}
                          >
                            Location Refresh
                          </a>
                        </MDButton>
                      ) : (
                        <MDButton
                          variant="contained"
                          color="info"
                          size="small"
                          sx={{ width: '100px' }}
                        >
                          <a
                            href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.ASSOCIATE}&client_id=${env.GHL.Location_CLIENT_ID}&scope=${env.GHL.Location_SCOPE}&state=${brand.ghl?.location_id}`}
                            style={{ color: 'white' }}
                          >
                            Location connect
                          </a>
                        </MDButton>
                      )}
                    </MDBox>
                  </MDBox>
                  <MDTypography id="modal-modal-title" variant="h6" component="h2" mt={3}>
                    Stripe Api Key
                  </MDTypography>
                  <div className="row">
                    <div className="col-sm-12">
                      <input
                        type="text"
                        value={strpe_api_key}
                        onChange={(e) => setstrpe_api_key(e.target.value)}
                        className=" form-control"
                        name="strpe_api_key"
                        placeholder="Enter Location Api Key"
                      />
                    </div>
                  </div>
                  <MDTypography id="modal-modal-title" sx={{ mt: 4 }} variant="h6" component="h2">
                    Stripe Public Key
                  </MDTypography>
                  <div className="row">
                    <div className="col-sm-12">
                      <input
                        type="text"
                        value={strpe_public_key}
                        onChange={(e) => setstrpe_public_key(e.target.value)}
                        className=" form-control"
                        name="strpe_public_key"
                        placeholder="Enter Stripe Public Key"
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-12 d-flex justify-content-end ">
                      {saved_data === '' ? (
                        <>
                          <MDButton
                            variant="gradient"
                            color="info"
                            type="submit"
                            loading={processing}
                            disabled={processing}
                          >
                            Save
                          </MDButton>
                        </>
                      ) : (
                        <>
                          <MDButton
                            variant="gradient"
                            color="info"
                            type="submit"
                            loading={processing}
                            disabled={processing}
                          >
                            Update
                          </MDButton>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </MDBox>

              {/* <MDBox sx={{ display: 'flex', flexDirection: 'column' }}>
                <img src={ghlLogo} alt="logo" width={100} />

                {saved_data?.agency_ghl != undefined ? (
                  <MDButton
                    variant="contained"
                    color="success"
                    size="small"
                    sx={{ width: '100px' }}
                  >
                    <a
                      href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.AGENCY}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.APPSCOPE}`}
                      style={{ color: 'white' }}
                    >
                      Agency Refresh
                    </a>
                  </MDButton>
                ) : (
                  <MDButton variant="contained" color="info" size="small" sx={{ width: '100px' }}>
                    <a
                      href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.AGENCY}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.APPSCOPE}`}
                      style={{ color: 'white' }}
                    >
                      Agency connect
                    </a>
                  </MDButton>
                )}
              </MDBox> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default Integration
