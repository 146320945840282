import { Card, Grid } from '@mui/material'
import React from 'react'
import TicketTabs from './components/tabs/index.js'
import Plan from './components/Plan/index.js'
import Features from './components/Features'
import ChurnedReasons from './components/ChurnedReasons'
import MDBox from 'components/MDBox/index.js'
import MDTypography from 'components/MDTypography/index.js'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout/index.js'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar/index.js'
function index() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card
              sx={{
                boxShadow: '0 3px 5px 0 rgba(0,0,0,.06)',
                border: '1px solid #cfd7df',
                borderRadius: '8px',
              }}
            >
              <MDBox p={3}>
                <MDTypography variant="h5">Health </MDTypography>
              </MDBox>

              <MDBox component="form">
                <Grid container spacing={3} paddingX={3} paddingBottom={4}>
                  <Grid item xs={12}>
                    <TicketTabs elements={[<Plan key={1} />, <Features key={2} />, <ChurnedReasons key={3} />]} />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default index
