import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import TicketTabs from "./components/tabs/index.js";
import Category from "./components/Category/index.js";
import Type from "./components/Type";
import Group from "./components/Group";
// import MDSelect from "components/MDSelect/index.js";
import { useUserInfo } from "context/user.js";
import { useAgencyInfo } from "context/agency.js";
import Loader from "examples/Loader/index.js";
import { useAppServices } from "hook/services.js";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout/index.js";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/index.js";
function index() {
  const [user] = useUserInfo();
  const AppService=useAppServices()
  const [agency] = useAgencyInfo();
  const [team, setTeam] = useState([]);
  const [processing, setProcessing] = useState(true);

  const GetTeam = async () => {
    const { response } = await AppService.user.filter({
      query: `agency_id=${agency._id}`,
    });
    if (response) {
      var filterusers = response.data.filter(function (item) {
        return item._id != user._id;
      });
      const tempteam = [];
      for (let index = 0; index < filterusers.length; index++) {
        const element = filterusers[index];
        tempteam.push({
          label: element.username,
          value: `${element.username}-${element._id}`,
        });
      }
      setTeam(tempteam);
    }

    setProcessing(false);
  };
  const onLoad = () => {
    GetTeam();
  };
  useEffect(() => {
    onLoad();
  }, []);
  const handleAgent=async(e)=>{
    const payload = {
      _id:agency._id,
      default_agent: e.value,
    };
    const { response } = await AppService.agency.update({
      payload,
    });
  }
  return (
    <DashboardLayout>
      <DashboardNavbar/>
      {processing ? (
        <Loader />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card
              sx={{
                boxShadow: "0 3px 5px 0 rgba(0,0,0,.06)",
                border: "1px solid #cfd7df",
                borderRadius: "8px",
              }}
            >
              <MDBox
                p={3}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={"10px"}
              >
                <MDTypography variant="h5">Tickets </MDTypography>
                {/* <MDSelect onChange={(e)=>handleAgent(e)} placeholder="Select Default Agent.." options={team} /> */}
              </MDBox>

              <MDBox component="form">
                <Grid container spacing={3} paddingX={3} paddingBottom={4}>
                  <Grid item xs={12}>
                    <TicketTabs
                      elements={[<Category key={1} />, <Type key={2} />, <Group key={3} />]}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
}

export default index;
