import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import React from 'react'
import FeaturesModal from '../Modals/FeaturesModal'
import dataTableData from '../data/features'
import DataTable from '../DataTable'

function Features() {
  const { data, processing, handleRefresh } = dataTableData()
  return (
    <MDBox>
      <Grid container rowSpacing={3}>
        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
          <FeaturesModal handleRefresh={handleRefresh} />
        </Grid>
        <Grid item xs={12}>
          <DataTable table={data} canSearch />
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default Features
