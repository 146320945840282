import { Card, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import React from 'react'
import SnapshotModal from './components/Modals/SnapshotModal'
import dataTableData from './components/data/snapshot'
import DataTable from './components/DataTable'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'

function Snapshots() {
  const { data, processing, handleRefresh } = dataTableData()
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card
          sx={{
            boxShadow: '0 3px 5px 0 rgba(0,0,0,.06)',
            border: '1px solid #cfd7df',
            borderRadius: '5px',
          }}
        >
          <MDBox p={3}>
            <MDTypography variant="h5">Snapshots </MDTypography>
          </MDBox>
          <Grid container p={5} rowSpacing={3}>
            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
              <SnapshotModal handleRefresh={handleRefresh} />
            </Grid>
            <Grid item xs={12}>
              <DataTable table={data} canSearch />
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    </DashboardLayout>
  )
}

export default Snapshots
