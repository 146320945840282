import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import React from 'react'
import dataTableData from '../data/churnedreasons'
import DataTable from '../DataTable'
import AddChurnedReasonModal from '../Modals/AddChurnedReasonModal'

function ChurnedReasons() {
  const { data, processing, handleRefresh } = dataTableData()
  return (
    <MDBox>
      <Grid container rowSpacing={3}>
        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
          <AddChurnedReasonModal handleRefresh={handleRefresh} />
        </Grid>
        <Grid item xs={12}>
          <DataTable table={data} canSearch />
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default ChurnedReasons
