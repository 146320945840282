import MDButton from 'components/MDButton'
import React, { useState } from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import PropTypes from 'prop-types'
import MDTypography from 'components/MDTypography'
import { useAppServices, useUploadImage } from 'hook/services'
import { useAgencyInfo } from 'context/agency'
import { Edit, EventNote, Note } from '@mui/icons-material'
import Editor from '../editor'
import { useLocation, useParams } from 'react-router-dom'
import MDInput from 'components/MDInput'

const PlanEditModal = ({ handleRefresh, data }) => {
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const params = useParams()
  const handlemodal = (event) => {
    event.stopPropagation()
    setOpenAddProduct(true)
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px  #000',
    boxShadow: 24,
    p: 4,
  }
  function AddProduct({ open, onClose }) {
    const [agency] = useAgencyInfo()
    const { state } = useLocation()
    const [processing, setProcessing] = useState(false)
    const [message, setmessage] = useState('')
    const AppService = useAppServices()
    const handleSubmit = async (e) => {
      setProcessing(true)
      e.preventDefault()
      const payload = {
        name: e.target.name.value,
        color: e.target.color.value,
        score: e.target.score.value,
        type: 'super_admin',
        allowed_hours: e.target.allowed_hours.value,
        agency_id: agency._id,
        _id: data._id,
      }

      const { response } = await AppService.health_plan.update({
        payload,
        toaster: true,
      })
      setProcessing(false)
      if (response) {
        onClose()
        handleRefresh()
      }
    }

    return (
      <MDBox>
        <MDModal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDTypography variant="h6">Edit Plan</MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography
                variant="button"
                sx={{ fontSize: '14px', fontWeight: '500', marginBottom: '10px' }}
              >
                Name
              </MDTypography>
              <MDInput
                defaultValue={data?.name}
                label="Plan Name"
                placeholder="Plan Name"
                name="name"
                fullWidth
              />
            </MDBox>
            <MDBox>
              <MDTypography
                variant="button"
                sx={{ fontSize: '14px', fontWeight: '500', marginBottom: '10px' }}
              >
                Color
              </MDTypography>
              <MDInput
                defaultValue={data?.color}
                type="color"
                className="color-input"
                name="color"
                fullWidth
              />
            </MDBox>
            <MDBox>
              <MDTypography
                variant="button"
                sx={{ fontSize: '14px', fontWeight: '500', marginBottom: '10px' }}
              >
                Allowed Free Hours
              </MDTypography>
              <MDInput
                type="number"
                label="Feature Score"
                placeholder="Feature Score"
                defaultValue={data?.allowed_hours}
                name="allowed_hours"
                fullWidth
              />
            </MDBox>
            <MDBox>
              <MDTypography
                variant="button"
                sx={{ fontSize: '14px', fontWeight: '500', marginBottom: '10px' }}
              >
                Score
              </MDTypography>
              <MDInput
                defaultValue={data?.score}
                type="number"
                label="Feature Score"
                placeholder="Feature Score"
                name="score"
                fullWidth
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mt: 4, mb: 1 }}
            >
              <MDBox>
                <MDButton
                  variant="gradient"
                  color="white"
                  sx={{
                    mr: 2,
                    '&:hover': {
                      background: 'white',
                    },
                  }}
                  size="small"
                  onClick={onClose}
                >
                  Cancel
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="dark"
                  type="submit"
                  loading={processing}
                  disabled={processing}
                  size="small"
                >
                  Edit
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDModal>
      </MDBox>
    )
  }
  const closeAddProduct = () => {
    // if (subscription?._id)
    setOpenAddProduct(false)
  }
  return (
    <>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
      <Edit sx={{ cursor: 'pointer' }} onClick={handlemodal} />
    </>
  )
}

export default PlanEditModal
PlanEditModal.propTypes = {
  open: PropTypes.bool,
  handleRefresh: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}
