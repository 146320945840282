import MDButton from 'components/MDButton'
import React, { useState } from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import PropTypes from 'prop-types'
import MDTypography from 'components/MDTypography'
import { useAppServices, useUploadImage } from 'hook/services'
import { useAgencyInfo } from 'context/agency'
import { EventNote, Note } from '@mui/icons-material'
import Editor from '../editor'
import { useLocation, useParams } from 'react-router-dom'
import MDInput from 'components/MDInput'

const AddChurnedReasonModal = ({ handleRefresh }) => {
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const params = useParams()
  const handlemodal = (event) => {
    event.stopPropagation()
    setOpenAddProduct(true)
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px  #000',
    boxShadow: 24,
    p: 4,
  }
  function AddProduct({ open, onClose }) {
    const [agency] = useAgencyInfo()
    const { state } = useLocation()
    const [processing, setProcessing] = useState(false)
    const [message, setmessage] = useState('')
    const AppService = useAppServices()
    const handleSubmit = async (e) => {
      setProcessing(true)
      e.preventDefault()
      const payload = {
        reason: e.target.reason.value,
        type: 'super_admin',
        agency_id: agency._id,
      }
      const { response } = await AppService.churned_reasons.create({
        payload,
        toaster: true,
      })
      setProcessing(false)
      if (response) {
        onClose()
        handleRefresh()
      }
    }

    return (
      <MDBox>
        <MDModal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ background: '#ffffff' }}
        >
          <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
            <MDBox display="flex" alignItems="center" mb={2}>
              <MDTypography variant="h6">Add Churned Reason</MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography
                variant="button"
                sx={{ fontSize: '14px', fontWeight: '500', marginBottom: '10px' }}
              >
                Churned Reason
              </MDTypography>
              <MDInput label="Churned Reason" placeholder="Churned Reason" name="reason" fullWidth />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mt: 4, mb: 1 }}
            >
              <MDBox>
                <MDButton
                  variant="gradient"
                  color="white"
                  sx={{
                    mr: 2,
                    '&:hover': {
                      background: 'white',
                    },
                  }}
                  size="small"
                  onClick={onClose}
                >
                  Cancel
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="dark"
                  type="submit"
                  loading={processing}
                  disabled={processing}
                  size="small"
                >
                  Add
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDModal>
      </MDBox>
    )
  }
  const closeAddProduct = () => {
    // if (subscription?._id)
    setOpenAddProduct(false)
  }
  return (
    <>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
      <MDButton
        onClick={handlemodal}
        variant="contained"
        sx={{
          border: '1px solid hsl(205, 53%, 88%)',
          boxShadow: 'none',
          Background: 'transparent',
          fontSize: '12px',
          marginRight: '15px',
          color: 'hsl(205, 47%, 42%)',
          fontWeight: '500',
          padding: '5px 12px',
          minHeight: '2.2rem',
          borderRadius: '8px',
          '&:hover': {
            boxShadow: 'none',
            border: '1px solid #1554ED !important',
            background: 'rgb(0, 102, 204) !important',
            color: 'white !important',
            transform: 'scale(1) !important',
          },
        }}
      >
        Add Churned Reason
      </MDButton>
    </>
  )
}

export default AddChurnedReasonModal
AddChurnedReasonModal.propTypes = {
  open: PropTypes.bool,
  handleRefresh: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}
